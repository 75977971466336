.how-it-works {
    background-color: grey;
    border-radius: 5px;
    width: 70%;
}

button {
    border-radius: 5px;
    background-color: black;
    color: wheat;
    outline: 1px solid wheat;
    width: 100px
}