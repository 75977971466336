.map-container {
	border-radius: 5px;
	height: 60vh;
	margin: auto;
	width: 70%;
}

.menu {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.menu-button:hover {
	border-radius: 10%;
	opacity: 60%;
	cursor: pointer;
	margin-left: 1%;
	margin-right: 1%;
	padding: 1%;
}

.menu-button {
	border-radius: 10%;
	margin-left: 1%;
	margin-right: 1%;
	margin-bottom: 1%;
	padding: 1%;
}

.house-info {
	color: black;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: wheat;
	position: relative;
	bottom: 100%;
	left: 100%;
}

.no-dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
	position: relative;
	bottom: 100%;
	left: 100%;
}