.table-column {
    width: 100px;
}

.table {
    margin: auto;
    text-align: left;
}

.table td {
    border-top: 5px solid lightgray;
}

.list-button-menu button {
    margin: 1%;
}